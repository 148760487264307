import React from 'react';
import { useApplicationContext } from '../../context/ApplicationContext';
import { ConvenioService } from '../../services/api/convenios/ConvenioService';
import { CountService } from '../../services/api/counts/CountService';

export function useCount() {
  const [associados, setAssociados] = React.useState(0);
  const [planos, setPlanos] = React.useState(0);
  const [convenios, setConvenios] = React.useState(0);
  const [requisicoes, setRequisicoes] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [totalElements, setTotalElements] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);
  const { changeErrorAlert } = useApplicationContext();

  const getAssociadosCount = async () => {
    try {
      setLoading(true);
      const data = await CountService.countAssociados();
      setAssociados(data);
    } catch (err: any) {
      changeErrorAlert({
        error: true,
        message: err.message,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const getPlanosCount = async () => {
    try {
      setLoading(true);
      const data = await CountService.countPlanos();

      setPlanos(data);
    } catch (err: any) {
      changeErrorAlert({
        error: true,
        message: err.message,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };
  const getConvenioCount = async () => {
    try {
      setLoading(true);
      const data = await CountService.countConvenio();
      setConvenios(data);
    } catch (err: any) {
      changeErrorAlert({
        error: true,
        message: err.message,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };
  const getRequisicaoCount = async () => {
    try {
      setLoading(true);
      const data = await CountService.countRequisição();
      setRequisicoes(data);
    } catch (err: any) {
      changeErrorAlert({
        error: true,
        message: err.message,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };
  return {
    associados,
    planos,
    convenios,
    requisicoes,
    getPlanosCount,
    getConvenioCount,
    getRequisicaoCount,
    getAssociadosCount,
    loading,
  };
}
