import React from 'react';


export function useDebounce() {


  const debouncing = React.useRef<NodeJS.Timeout>();

  const debounce = React.useCallback((func: ()=> void)=>{
    if(debouncing.current){
      clearTimeout(debouncing.current);
    }
    debouncing.current = setTimeout(()=> func(), 600);
  },[]);
  return { debounce };
}