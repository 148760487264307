import { Box, Button, Icon, Paper, TextField, useTheme } from '@mui/material';
import React from 'react';

type ToolbarProps = {
 value?: string,
 onChange?: (text: string) => void,
 showField?: boolean,
 showButton?: boolean,
 textButton?: string,
 handleAction?: () => void,
}
export const Toolbar: React.FC<ToolbarProps> = ({
  value = '',
  onChange,
  showField  = false,
  textButton = 'Novo',
  showButton = true,
  handleAction}) => {

  const theme = useTheme();

  return (
    <Box
      height={theme.spacing(7)}
      component={Paper}
      marginX={1}
      padding={1}
      paddingX={2}
      display="flex"
      gap={1}
      alignItems="center"
    >
      {showField && 
        <TextField
          placeholder='Pesquisar por nome'
          size='small'
          sx={{ width: '100%'}}
          value={value}
          onChange={(e)=>onChange?.(e.target.value)}
        />
      }

      {showButton && 
        <Box flex={1}  display="flex" justifyContent="end">
          <Button
            variant='contained'
            disableElevation
            color='primary'
            onClick={handleAction}
            endIcon={<Icon>add</Icon>}
          >{textButton}
          </Button>
        </Box>
      }
    </Box>
  );
};