import { Api } from '../axios-config';

const findAll = async (page: number, limit: number, searchParam: string): Promise<any | Error> => {
  try {
    const relative = `/convenios?fantasyName=${searchParam}&page=${page}&limit=${limit}`;
    const { data } = await Api.get(relative);
    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao listar convênios.');
  }
};

const findSearchAll = async (page: number, limit: number, searchParam: string): Promise<any | Error> => {
  try {
    const relative = `/convenios/search?fantasyName=${searchParam}&page=${page}&limit=${limit}`;
    const { data } = await Api.get(relative);
    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao listar convênios.');
  }
};
const findById = async (id: number): Promise<any | Error> => {
  try {
    const relative = `/convenios/${id}`;
    const { data } = await Api.get(relative);
    return data;
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o convênio.');
  }
};
const create = async (data: any): Promise<any | Error> => {
  try {
    const { data: result } = await Api.post('/convenios', data);
    return result;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao cadastrar o convênio.');
  }
};
const udpateById = async (id: number, data: any): Promise<void | Error> => {
  try {
    const { data: result } = await Api.put(`/convenios/${id}`, data);
    return result;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao atualizar o convênio.');
  }
};
const deleteById = async (id: number): Promise<void | Error> => {
  try {
    await Api.put(`/convenios/deactive/${id}`);
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao desativar o convênio.');
  }
};

export const ConvenioService = {
  findAll,
  findSearchAll,
  findById,
  create,
  udpateById,
  deleteById
};