import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, LinearProgress, Pagination, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, useTheme } from '@mui/material';
import React from 'react';
import { Toolbar } from '../../../shared/components';
import { useConvenios } from '../../../shared/hooks/convenios/useConvenios';
import { LayoutBase } from '../../../shared/layouts';
import { IConvenio } from '../../../shared/models/Convenio';
import { Environment } from '../../../shared/environment';
import { CreateConvenioModal } from '../components/modal/Create/CreateConvenio';
import { useDebounce } from '../../../shared/hooks/useDebounce';
import { useApplicationContext } from '../../../shared/context/ApplicationContext';
import { UpdateConvenioModal } from '../components/modal/Update/UpdateConvenio';
import { ConvenioService } from '../../../shared/services/api/convenios/ConvenioService';

interface IPagination {
  page: number,
  total: number
}
export const ListConvenios: React.FC = () => {

  const [search, setSearch] = React.useState('');
  const { errorAlert, changeErrorAlert } = useApplicationContext();
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [convenio, setConvenio] = React.useState<any>({});
  const [idDelete, setIdDelete] = React.useState<number>(0);

  const { debounce } = useDebounce();
  const [pagination, setPagination] = React.useState<IPagination>(()=>({
    page:1,
    total:0
  }));
  const { convenios, getConvenios, loading, totalPage } = useConvenios();

  React.useEffect(()=>{
    getConvenios({});
  },[]);

  const onChangeSearch = React.useCallback((value: string) =>{
    setSearch(value);
    console.log(value);
    debounce(()=>{
      getConvenios({searchParam: value});
    });

  },[search]);

  const factoryConvenio = ({local, name, responsavel, telefone}: IConvenio) => {
    return { name, local, responsavel, telefone };
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickEditOpen = React.useCallback((_convenio: any) => {
    setConvenio(_convenio);
    setOpenEdit(true);
  },[convenio]);

  const handleEditClose = React.useCallback(() => {
    setOpenEdit(false);
  },[convenio]);

  const handleClickDeleteOpen = (id: number) => {
    setIdDelete(id);
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const deleteConvenio = async (id: number) => {
    try {
      await ConvenioService.deleteById(id);
      getConvenios({});
      handleDeleteClose();
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <LayoutBase
      title='Convênios'
      toolbar={(<Toolbar
        showField
        showButton
        value={search}
        onChange={onChangeSearch}
        handleAction={handleClickOpen}
      />)}
    >

      <TableContainer sx={{ minWidth: 400, width: 'auto', m: 1 }} component={Paper}>
        <Table size="small" aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell>CNPJ</TableCell>
              <TableCell>INSC. ESTADUAL</TableCell>
              <TableCell>INSC. MUNICIPAL</TableCell>
              <TableCell>NOME</TableCell>
              <TableCell>RESPONSÁVEL</TableCell>
              <TableCell>AÇÕES</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {convenios.map((row: any, index) => 
              <TableRow
                key={index}
                sx={
                  { '&:last-child td, &:last-child th': { border: 0 } }
                }
              >
                <TableCell component="th" scope="row">{row?.cnpj}</TableCell>
                <TableCell>{row?.stateResgistration}</TableCell>
                <TableCell>{row?.municipalResgistration}</TableCell>
                <TableCell>{row?.fantasyName}</TableCell>
                <TableCell>{row?.responsible}</TableCell>
                <TableCell colSpan={1}>
                  <Button
                    variant='outlined'
                    disableElevation
                    onClick={()=> handleClickEditOpen(row)}
                    sx={{
                      marginRight: 1
                    }}
                  >
                    <Icon>mode_edit</Icon>
                  </Button>
                  <Button
                    variant='outlined'
                    disableElevation
                    color='error'
                    onClick={()=>handleClickDeleteOpen(row.id)}
                  >
                    <Icon>delete</Icon>
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {convenios.length == 0 && !loading &&
            <caption>{Environment.LIST_EMPTY}</caption>
          }
          <TableFooter>
            {loading && 
              <TableRow>
                <TableCell colSpan={5}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            }
            {convenios.length >0 && 
              <TableRow>
                <TableCell colSpan={5}>
                  <Pagination
                    color='primary'
                    variant='outlined'
                    page={pagination.page}
                    count={totalPage}
                    onChange={(_, page)=>{
                      // console.log(page);
                      setPagination(prev => ({
                        ...prev,
                        page
                      }));
                      getConvenios({ page: page-1});
                    }}
                  />
                </TableCell>
              </TableRow>
            }
          </TableFooter>
        </Table>
      </TableContainer>

      <CreateConvenioModal
        open={open}
        getConvenios={getConvenios}
        onClose={handleClose}
        title="Novo Convênio"
        description='Formulário de cadastro de convênios da ASSTRANS'
      />

      <UpdateConvenioModal
        convenio={convenio}
        open={openEdit}
        getConvenios={getConvenios}
        onClose={handleEditClose}
        title="Atualizar Convênio"
        description='Formulário de atualização de convênios da ASSTRANS'
      />

      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Desativar Convênio'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Você deseja remover este Convênio?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>NÃO</Button>
          <Button onClick={()=>deleteConvenio(idDelete)} autoFocus>
            SIM
          </Button>
        </DialogActions>
      </Dialog>

      {errorAlert && 

        <Snackbar  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={()=>changeErrorAlert(null)} open={errorAlert.error} autoHideDuration={3000}>
          <Alert onClose={()=>changeErrorAlert(null)} severity={errorAlert.type} sx={{ width: '100%' }}>
            {errorAlert?.message}
          </Alert>
        </Snackbar>
      }

    </LayoutBase>
  );
};
