import React from 'react';
import { LoadingButton } from '@mui/lab';
import { DialogActions, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { BaseModal } from '../../../../../shared/components/modal/BaseModal';
import { useApplicationContext } from '../../../../../shared/context/ApplicationContext';
import { ConvenioService } from '../../../../../shared/services/api/convenios/ConvenioService';
import { validationCreateConvenio } from '../../../validations/convenios.schema';
import InputMask from 'react-input-mask';
import { useConvenios } from '../../../../../shared/hooks/convenios/useConvenios';

type UpdateConvenioProps = {
  open: boolean,
  onClose: () => void,
  title: string,
  description?: string,
  getConvenios: any,
  convenio: any
}
export const UpdateConvenioModal: React.FC<UpdateConvenioProps> = ({ onClose, convenio, getConvenios,...rest}) => {

  const { loading, updateConvenio } = useConvenios();

  const factoryData = () => {
    console.log(convenio);

    return {
      cnpj: convenio?.cnpj,
      stateResgistration: convenio?.stateResgistration,
      municipalResgistration: convenio?.municipalResgistration,
      address: convenio?.address,
      fantasyName: convenio?.fantasyName,
      corporateName: convenio?.corporateName,
      responsible: convenio?.responsible,
      tel: convenio?.tel,
      email: convenio?.email,
      phoneStores: convenio?.phoneStores,
      referencePoint: convenio?.referencePoint,
      zoneCity: convenio?.zoneCity ?? 'NORTE'
    };
  };

  const {
    touched,
    errors,
    resetForm,
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit
  } = useFormik<any>({
    onSubmit: async (values) =>{
      await updateConvenio(convenio.id, values);
      getConvenios({});
      resetForm();
      onClose();
    },
    validationSchema: validationCreateConvenio,
    enableReinitialize: true,
    initialValues: {
      ...factoryData()
    }
  });

  return (
    <BaseModal
      loading={loading}
      onClose={onClose}
      onSubmit={handleSubmit}
      hasForm
      {...rest}
    >
      <form onSubmit={handleSubmit}>
        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <InputMask
            value={values?.cnpj}
            //onChange={handleChange('cnpj')}
            onChange={(value) =>{
            // console.log('v', value.target.value.replace(/[^0-9]+/g, ''));
              setFieldValue('cnpj', value.target.value.replace(/[^0-9]+/g, ''));
            }}
            onBlur={()=>handleBlur('cnpj')}
            mask="99.999.999/9999-99"
          >
            <TextField
              margin="dense"
              name="cnpj"
              label="CNPJ/CGC"
              type="text"
              fullWidth
              variant="outlined"
              // value={values.cnpj}
              // onChange={handleChange('cnpj')}
              // onBlur={handleBlur('cnpj')}
              error={!!(errors?.cnpj && touched?.cnpj)}
            />

          </InputMask>


        </Stack>

        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <InputMask
            value={values?.municipalResgistration}
            onBlur={()=>handleBlur('municipalResgistration')}
            onChange={(value) =>{
              setFieldValue('municipalResgistration', value.target.value.replace(/[^0-9]+/g, ''));
            }}
            mask="99999999999"
          >
            <TextField
              margin="dense"
              name="municipalResgistration"
              label="INSC. MUNIC "
              type="text"
              fullWidth
              variant="outlined"
              //value={values.municipalResgistration}
              // onChange={handleChange('municipalResgistration')}
              // onBlur={handleBlur('municipalResgistration')}
              error={!!(errors?.municipalResgistration && touched?.municipalResgistration)}
            />
          </InputMask>

          <InputMask
            value={values?.stateResgistration}
            onBlur={()=>handleBlur('stateResgistration')}
            onChange={(value) =>{
              setFieldValue('stateResgistration', value.target.value.replace(/[^0-9]+/g, ''));
            }}
            mask="999999999.99-99"
          >
            <TextField
              margin="dense"
              name="stateResgistration"
              label="INSC. EST"
              type="text"
              fullWidth
              variant="outlined"
              // value={values.stateResgistration}
              // onChange={handleChange('stateResgistration')}
              // onBlur={handleBlur('stateResgistration')}
              error={!!(errors?.stateResgistration && touched?.stateResgistration)}
            />

          </InputMask>
        </Stack>

        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <TextField
            margin="dense"
            name="address"
            label="ENDEREÇO"
            type="text"
            fullWidth
            variant="outlined"
            value={values.address}
            onChange={handleChange('address')}
            onBlur={handleBlur('address')}
            error={!!(errors?.address && touched?.address)}
          />
        </Stack>

        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >

          <TextField
            margin="dense"
            name="fantasyName"
            label="NOME FANTASIA"
            type="text"
            fullWidth
            variant="outlined"
            value={values.fantasyName}
            onChange={handleChange('fantasyName')}
            onBlur={handleBlur('fantasyName')}
            error={!!(errors?.fantasyName && touched?.fantasyName)}
          />

          <TextField
            margin="dense"
            name="corporateName"
            label="RAZÃO SOCIAL"
            type="text"
            fullWidth
            variant="outlined"
            value={values.corporateName}
            onChange={handleChange('corporateName')}
            onBlur={handleBlur('corporateName')}
            error={!!(errors?.corporateName && touched?.corporateName)}
          />
        </Stack>

        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <TextField
            margin="dense"
            name="responsible"
            label="RESPONSÁVEL"
            type="text"
            fullWidth
            variant="outlined"
            value={values.responsible}
            onChange={handleChange('responsible')}
            onBlur={handleBlur('responsible')}
            error={!!(errors?.responsible && touched?.responsible)}
          />
        </Stack>

        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >

          <InputMask
            value={values?.tel}
            onBlur={()=>handleBlur('tel')}
            onChange={(value) =>{
              setFieldValue('tel', value.target.value.replace(/[^0-9]+/g, ''));
            }}
            mask="(99)99999-9999"
          >
            <TextField
              margin="dense"
              name="tel"
              label="TELEFONE"
              type="text"
              fullWidth
              variant="outlined"
              error={!!(errors?.tel && touched?.tel)}
            />
          </InputMask>

          <TextField
            margin="dense"
            name="fax"
            label="Email"
            type="text"
            fullWidth
            variant="outlined"
            value={values.email}
            onChange={handleChange('email')}
            onBlur={handleBlur('email')}
            error={!!(errors?.email && touched?.email)}
          />
        </Stack>

        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        // sx={{
        //   mt: 2,
        //   mb: 2
        // }}
        >

          {/* <TextField
          margin="dense"
          name="zoneCity"
          label="ZONA"
          select
          type="text"
          fullWidth
          variant="outlined"
          placeholder='ZONA'
          value={values.zoneCity}
          onChange={handleChange('zoneCity')}
          onBlur={handleBlur('zoneCity')}
          error={!!(errors?.zoneCity && touched?.zoneCity)}
        >
          <MenuItem value={'NORTE'}>NORTE</MenuItem>
          <MenuItem value={'SUL'}>SUL</MenuItem>
          <MenuItem value={'LESTE'}>LESTE</MenuItem>
          <MenuItem value={'OESTE'}>OESTE</MenuItem>

        </TextField> */}


          <TextField
            margin="dense"
            name="referencePoint"
            label="PONTO. REF."
            type="text"
            fullWidth
            variant="outlined"
            value={values.referencePoint}
            onChange={handleChange('referencePoint')}
            onBlur={handleBlur('referencePoint')}
          />
        </Stack>

        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <TextField
            margin="dense"
            name="phoneStores"
            label="TELEFONE LOJAS"
            type="text"
            fullWidth
            variant="outlined"
            value={values.phoneStores}
            onChange={handleChange('phoneStores')}
            onBlur={handleBlur('phoneStores')}
            error={!!(errors?.phoneStores && touched?.phoneStores)}
          />
        </Stack>

        <DialogActions>
          <LoadingButton
            variant='contained'
            loading={loading}
            type='submit'>Atualizar
          </LoadingButton>
        </DialogActions>
      </form>
    </BaseModal>
  );
};
