import React from 'react';
import { useApplicationContext } from '../../context/ApplicationContext';
import { ConvenioService } from '../../services/api/convenios/ConvenioService';

type UseConveniosProps = {
  page?: number,
  limit?: number,
  searchParam?: string,
}
export function useConvenios() {

  const [convenios, setConvenios] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [ totalElements, setTotalElements] = React.useState(0);
  const [ totalPage, setTotalPage] = React.useState(0);
  const { changeErrorAlert } = useApplicationContext();

  const saveConvenio = async (data: any) => {
    try {
      setLoading(true);
      const result = await  ConvenioService.create(data);
      changeErrorAlert(
        {
          error: true,
          message: 'Cadastro efetuado com sucesso!',
          type: 'success'
        });
    } catch (err: any) {
      console.log(err);
      changeErrorAlert(
        {
          error: true,
          message: err.message,
          type: 'error'
        });
    }finally{
      setLoading(false);
    }
  };
  const updateConvenio = async (id: number,data: any) => {
    try {
      setLoading(true);
      const result = await ConvenioService.udpateById(id,data);
      changeErrorAlert(
        {
          error: true,
          message: 'Atualização efetuada com sucesso!',
          type: 'success'
        });
    } catch (err: any) {
      changeErrorAlert(
        {
          error: true,
          message: err.message,
          type: 'error'
        });
    }finally{
      setLoading(false);
    }
  };
  const getConvenios = async ({ page = 0, limit = 10, searchParam=''}: UseConveniosProps ) => {
    try {
      setLoading(true);
      const data  = await ConvenioService.findAll(page,limit, searchParam);
      setTotalElements(data.totalElements);
      setTotalPage(data.totalPages);
      setConvenios(data.content);
    } catch (err: any) {
      changeErrorAlert(
        {
          error: true,
          message: err.message,
          type: 'error'
        });
    }finally{
      setLoading(false);
    }
  };
  return {
    convenios,
    getConvenios,
    saveConvenio,
    updateConvenio,
    totalElements,
    totalPage,
    loading
  };
}
