import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Toolbar } from '../../shared/components';
import { LayoutBase } from '../../shared/layouts';
import { useCount } from '../../shared/hooks/count/useCount';

export const Dashboard: React.FC = () => {
  const {
    associados,
    convenios,
    planos,
    requisicoes,
    getAssociadosCount,
    getConvenioCount,
    getPlanosCount,
    getRequisicaoCount,
    loading,
  } = useCount();

  useEffect(() => {
    getAssociadosCount();
    getConvenioCount();
    getPlanosCount();
    getRequisicaoCount();
  }, []);

  console.log(associados);

  return (
    <LayoutBase title="DASHBOARD" toolbar={<Toolbar showField />}>
      <Box
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Stack
          display={'flex'}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Box minWidth={275}>
            <Card
              sx={{
                mb: 1,
              }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Convênio
                </Typography>
                <Typography variant="h5" component="div">
                  {convenios}
                </Typography>
              </CardContent>
            </Card>
          </Box>

          <Box minWidth={275}>
            <Card
              sx={{
                mb: 1,
              }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Associados
                </Typography>
                <Typography variant="h5" component="div">
                  {associados}
                </Typography>
              </CardContent>
            </Card>
          </Box>

          <Box minWidth={275}>
            <Card
              sx={{
                mb: 1,
              }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Planos
                </Typography>
                <Typography variant="h5" component="div">
                  {planos}
                </Typography>
              </CardContent>
            </Card>
          </Box>

          <Box minWidth={275}>
            <Card
              sx={{
                mb: 1,
              }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Requisicoes
                </Typography>
                <Typography variant="h5" component="div">
                  {requisicoes}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Stack>
      </Box>
    </LayoutBase>
  );
};
