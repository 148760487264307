import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Box, Theme, ThemeProvider as ThemeProviderMui } from '@mui/material';
import { LightTheme, DarkTheme } from '../themes';

interface IThemeProviderProps {
  children: React.ReactNode;
}
interface IThemeContextData {
  themeName: 'light' | 'dark';
  toogleTheme: () => void;
}
const ThemeContext = createContext({} as IThemeContextData);
export const useThemeContext = () => {
  return useContext(ThemeContext);
};
export const ThemeProvider = ({ children} : IThemeProviderProps) => {
  const [themeName, setThemeName] = useState<'light' | 'dark'>('light');

  const toogleTheme = useCallback(()=>{
    setThemeName(prev => prev === 'light'? 'dark' : 'light');
  },[]);

  const theme = useMemo(()=> {
    if(themeName === 'light') return LightTheme;
    return DarkTheme;
  },[themeName]);

  return (
    <ThemeContext.Provider value={{ themeName, toogleTheme }}>
      <ThemeProviderMui theme={theme}>
        <Box width={'100vw'} height={'100vh'} bgcolor={theme.palette.background.default}>
          {children}
        </Box>
      </ThemeProviderMui>
    </ThemeContext.Provider>
  );
};