import { Icon, IconButton, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useDrawerContext } from '../context';

type LayoutBaseProps = {
  title: string,
  toolbar?: React.ReactNode,
  children: React.ReactNode,
  fontSmall?: boolean
}
export const LayoutBase = ({children, title, toolbar, fontSmall}: LayoutBaseProps) => {
  const smScreen = useMediaQuery((theme: Theme)=>theme.breakpoints.down('sm'));
  const mdScreen = useMediaQuery((theme: Theme)=>theme.breakpoints.down('md'));
  const theme = useTheme();
  const { toogleDrawerOpen } = useDrawerContext();

  return (
    <Box
      display="flex"
      flexDirection={'column'}
      height={'100%'}
      gap={1}
    >
      <Box
        display="flex"
        padding={1}
        height={theme.spacing(smScreen ? 6 : mdScreen ? 8 : 12)}
        alignItems="center"
        gap={1}
      >
        {smScreen && 
          <IconButton onClick={toogleDrawerOpen}>
            <Icon>menu</Icon>
          </IconButton>
        }
        {fontSmall ? 
          <Typography>
            <b> {title}</b>
          </Typography>

          : 
          <Typography
            variant={smScreen ? 'h5' : mdScreen ? 'h4' : 'h3'}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {title}
          </Typography>

        }
      </Box>
      {toolbar && 
        <Box>
          {toolbar}
        </Box>
      }
      <Box flex={1} overflow="auto">
        {children}
      </Box>
    </Box>
  );
};