import { createContext, useCallback, useContext, useMemo, useState } from 'react';

interface IDrawerProviderProps {
  children: React.ReactNode;
}
interface IDrawerContextData {
  isDrawerOpen: boolean;
  toogleDrawerOpen: () => void;
}
const DrawerContext = createContext({} as IDrawerContextData);
export const useDrawerContext = () => {
  return useContext(DrawerContext);
};
export const DrawerProvider = ({ children} : IDrawerProviderProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toogleDrawerOpen = useCallback(()=>{
    setIsDrawerOpen(prev => !prev);
  },[]);

  return (
    <DrawerContext.Provider value={{ isDrawerOpen, toogleDrawerOpen }}>
      {children}
    </DrawerContext.Provider>
  );
};