import { Api } from '../axios-config';




const countAssociados = async (): Promise<any | Error> => {
  try {
    const relative = '/associados/count';
    const { data } = await Api.get(relative);
    return data;
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o contador.');
  }
};
const countPlanos = async (): Promise<any | Error> => {
  try {
    const relative = '/planos/count';
    const { data } = await Api.get(relative);
    return data;
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o contador.');
  }
};
const countConvenio = async (): Promise<any | Error> => {
  try {
    const relative = '/convenios/count';
    const { data } = await Api.get(relative);
    return data;
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o contador.');
  }
};
const countRequisição = async (): Promise<any | Error> => {
  try {
    const relative = '/requisicoes/count';
    const { data } = await Api.get(relative);
    return data;
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o contador.');
  }
};



export const CountService = {
  countAssociados,
  countPlanos,
  countConvenio,
  countRequisição
};