import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

type BaseModalProps = {
  children?: React.ReactNode,
  open: boolean,
  loading?: boolean,
  onClose: () => void,
  title?: string,
  hasForm?: boolean,
  description?: string,
  onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined
  buttons?: boolean,
}
export const BaseModal: React.FC<BaseModalProps> = ({title,
  hasForm,
  description,
  children,
  open,
  onSubmit,
  onClose,
  buttons,
  loading}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const isForm = () => {
    if(hasForm){
      return (
        // <form onSubmit={onSubmit}>
        <>
          <DialogContent>
            <DialogContentText sx={{mb:2}}>
              {description}
            </DialogContentText>
            {children}
          </DialogContent>
          <>
            {buttons && 
              <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <LoadingButton
                  variant='contained'
                  loading={loading}
                  type='submit'>Salvar
                </LoadingButton>
              </DialogActions>
            }
          </>
        </>
      );
    }else{
      return (
        <DialogContent>
          <DialogContentText>
            {description}
          </DialogContentText>
          {children}
        </DialogContent>

      );
    }
  };
  return (
    <Dialog  fullScreen={fullScreen} open={open} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {isForm()}
    </Dialog>
  );
};
