import React from 'react';
import { useFormik } from 'formik';
import { validationCreateAssociado } from '../../../pages/associados/validations/associados.schema';
import { useApplicationContext } from '../../context/ApplicationContext';
import { AssociadoService } from '../../services/api/associados/AssociadoService';

type UseAssociadoProps = {
  getAssociados: any;
};

export function useAssociado(props: UseAssociadoProps, callback: any) {

  const [loading, setLoading] = React.useState(false);
  const { changeErrorAlert } = useApplicationContext();
  const [associado, setAssociado] = React.useState({} as any);
  const [dependentes, setDependentes] = React.useState([]);
  const [idAssociado, setIdAssociado] = React.useState(0);

  const factoryData = () => {
    return {
      name: '',
      email: '',
      zoneCity: '',
      matricula: '',
      rg: '',
      cpf: '',
      setor: '',
      celular: '',
      salarioBase: '',
    };
  };

  const {
    touched,
    errors,
    resetForm,
    setFieldValue,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik<any>({
    onSubmit: async (values) => {
      await saveAssociado(values);
      callback(1);
    },
    validationSchema: validationCreateAssociado,
    initialValues: {
      ...factoryData(),
    },
  });

  const saveAssociado = async (data: any) => {
    setLoading(true);
    try {
      const result = await AssociadoService.create(data);
      setIdAssociado(result.id);
      changeErrorAlert({
        error: true,
        message: 'Cadastro efetuado com sucesso!',
        type: 'success',
      });
      props.getAssociados({});
    } catch (err: any) {
      changeErrorAlert({
        error: true,
        message: err.message,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };
  const updateAssociado = async (id: any, values: any) => {
    setLoading(true);
    try {
      await AssociadoService.udpateById(id, values);
      changeErrorAlert({
        error: true,
        message: 'Associado atualizado com sucesso!',
        type: 'success',
      });
      props.getAssociados({});
    } catch (err: any) {
      changeErrorAlert({
        error: true,
        message: err.message,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };
  const getAssociadoById = async (id: number) => {
    setLoading(true);
    try {
      const result = await AssociadoService.findById(id);
      setAssociado(result);
    } catch (err: any) {
      console.log('Error', err);
    } finally {
      setLoading(false);
    }
  };

  const getDependentesByAssociado = async (id: number) => {
    setLoading(true);
    try {
      const result = await AssociadoService.findAllDependentesByAssociado(id);
      setDependentes(result);
    } catch (err: any) {
      console.log('Error', err);
    } finally {
      setLoading(false);
    }
  };

  return {
    saveAssociado,
    getAssociadoById,
    updateAssociado,
    getDependentesByAssociado,
    associado,
    dependentes,
    props: {
      touched,
      errors,
      resetForm,
      setFieldValue,
      values,
      handleChange,
      handleBlur,
      handleSubmit,
    },
    idAssociado,
    setIdAssociado,
    loading,
  };
}
