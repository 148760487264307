import { createTheme } from '@mui/material';
import { yellow, cyan, grey } from '@mui/material/colors';

export const DarkTheme = createTheme({
  palette:{
    mode: 'dark',
    primary: {
      main: yellow[700],
      dark: yellow[800],
      light: grey[700],
      contrastText: '#FFF'
    },
    secondary: {
      main: cyan[500],
      dark: cyan[400],
      light: cyan[300],
      contrastText: '#FFF'
    },
    background: {
      default: '#202124',
      paper: '#303134'
    }
  },
  typography:{
    allVariants:{
      color: '#FAFAFA'
    }
  }
});