import React, { useContext } from 'react';
import { createContext } from 'react';

type ErrorAlert = {
  error: boolean,
  message: string,
  type: 'success' | 'error' | 'warning' | 'info'
}
interface IApplicationProviderProps {
  children: React.ReactNode;
}
interface IApplicationContextData {
  errorAlert: null | ErrorAlert,
  changeErrorAlert: (error: ErrorAlert | null) => void
}

const ApplicationContext = createContext({} as IApplicationContextData);

export const useApplicationContext = () => {
  return useContext(ApplicationContext);
};

export const ApplicationProvider = ({children}: IApplicationProviderProps) => {
  const [errorAlert, setErrorAlert] = React.useState<null | ErrorAlert>(null);

  const changeErrorAlert = React.useCallback((err:ErrorAlert | null) => {
    setErrorAlert(prev => {
      if(err){
        const {error, message, type} = err;
        return (
          {
            ...prev,
            error,
            message,
            type
          }
        );
      }else{
        return null;
      }
    });
  },[]);

  return (
    <ApplicationContext.Provider value={{ errorAlert, changeErrorAlert }}>
      {children}
    </ApplicationContext.Provider>
  );
};