import * as yup from 'yup';

export const validationCreateAssociado = yup.object().shape({
  name: yup.string().required('Campo obrigatório.'),
  matricula: yup.string(),
  setor: yup.string(),
  rg: yup.string(),
  cpf: yup.string(),
  email: yup.string(),
  zoneCity: yup.string().required('Campo obrigatório'),
  celular: yup.string(),
  salarioBase: yup.string().required('Campo obrigatório.')
});

export const validationCreateDependente = yup.object().shape({
  nome: yup.string().required('Campo obrigatório.'),
  dataNascimento: yup.date()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .required('Campo obrigatório.'),
  grauParentesco: yup.string().required('Campo obrigatório.')
});