import { Api } from '../axios-config';

const findAll = async (page: number, limit: number, searchParam: string): Promise<any | Error> => {
  try {
    const relative = `/associados?search=${searchParam}&page=${page}&limit=${limit}`;
    const { data } = await Api.get(relative);
    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao listar convênios.');
  }
};

const findSearchAll = async (page: number, limit: number, searchParam: string): Promise<any | Error> => {
  try {
    const relative = `/associados/search?search=${searchParam}&page=${page}&limit=${limit}`;
    const { data } = await Api.get(relative);
    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao listar convênios.');
  }
};
const findAllDependentesByAssociado = async (idAssociado: number | string): Promise<any | Error> => {
  try {
    const { data } = await Api.get(`/associados/${idAssociado}/dependentes`);
    return data;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao listar os dependentes.');
  }
};
const findById = async (id: number): Promise<any | Error> => {
  try {
    const relative = `/associados/${id}`;
    const { data } = await Api.get(relative);
    return data;
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o convênio.');
  }
};
const create = async (data: any): Promise<any | Error> => {
  try {
    const { data: result } = await Api.post('/associados', data);
    return result;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao cadastrar o associado.');
  }
};

const createDependente = async (data: any): Promise<any | Error> => {
  try {
    const { data: result } = await Api.post(`/associados/${data.idAssociado}/dependente`, data);
    return result;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao cadastrar o dependente.');
  }
};
const updateDependenteById = async (id: number, data: any): Promise<any | Error> => {
  try {
    const { data: result } = await Api.put(`/associados/${id}/dependente`, data);
    return result;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao atualizar o dependente.');
  }
};
const udpateById = async (id: number, data: any): Promise<void | Error> => {
  try {
    const { data: result } = await Api.put(`/associados/${id}`, data);
    return result;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao atualizar o convênio.');
  }
};
const deleteById = async (id: number): Promise<void | Error> => {
  try {
    await Api.put(`/associados/deactive/${id}`);
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao desativar o convênio.');
  }
};
const deleteDependenteById = async (id: number): Promise<any | Error> => {
  try {
    const { data: result } = await Api.delete(`/associados/dependentes/${id}`);
    return result;
  } catch (error) {
    throw new Error((error as { message: string }).message || 'Erro ao desativar o dependente.');
  }
};

export const AssociadoService = {
  createDependente,
  updateDependenteById,
  findAllDependentesByAssociado,
  deleteDependenteById,
  findAll,
  findSearchAll,
  findById,
  create,
  udpateById,
  deleteById
};