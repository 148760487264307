import * as yup from 'yup';

export const validationCreateConvenio = yup.object().shape({
  cnpj: yup.string().required('Campo obrigatório.'),
  stateResgistration: yup.string(),
  municipalResgistration: yup.string(),
  address: yup.string().required('Campo obrigatório.'),
  fantasyName: yup.string().required('Campo obrigatório.'),
  corporateName: yup.string().required('Campo obrigatório.'),
  responsible: yup.string(),
  tel: yup.string().required('Campo obrigatório.'),
  email: yup.string().email().required('Campo obrigatório.'),
  phoneStores: yup.string()
});