import axios from 'axios';
import qs from 'qs';
import { Api } from '../axios-config';

const login = async (email: string, password: string): Promise<any | Error> => {
  try {

    const clientId = 'asstrans';
    const clientSecret = 'asstrans';
    const token = btoa(`${clientId}:${clientSecret}`);
    const params = {
      username: email,
      password,
      grant_type: 'password'
    };

    const headers = {
      Authorization: `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    const { data } = await Api.post('/oauth/token', qs.stringify(params), { headers });
    console.log(data);
    return data;
  } catch (error) {
    console.log('LALALA', error);
    throw new Error((error as { message: string }).message || 'Erro ao listar convênios.');
  }
};


export const AuthService = {
  login
};