import {
  Avatar,
  Divider,
  Drawer,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useDrawerContext, useThemeContext } from '../../context';
import { useAuthContext } from '../../context/AuthContext';
import MenuItem from './MenuItem';

const menu_list = [
  {
    label: 'Home',
    icon: 'home',
    to: '/pagina-inicial',
  },
  {
    label: 'Perfil',
    icon: 'person',
    to: '/perfil',
  },
  {
    label: 'Convênios',
    icon: 'villa',
    to: '/convenios',
  },
  {
    label: 'Associados',
    icon: 'people',
    to: '/associados',
  },
  {
    label: 'Planos',
    icon: 'dataset',
    to: '/planos',
  },
  {
    label: 'Requisições',
    icon: 'receipt',
    to: '/requisicoes',
  },
  {
    label: 'Relatórios',
    icon: 'assessment',
    to: '/relatorios',
  },
];
type MenuProps = {
  children: React.ReactNode;
};
export const Menu = ({ children }: MenuProps) => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { isDrawerOpen, toogleDrawerOpen } = useDrawerContext();
  const { toogleTheme } = useThemeContext();
  const { logout } = useAuthContext();

  return (
    <>
      <Drawer
        open={isDrawerOpen}
        variant={smScreen ? 'temporary' : 'permanent'}
        onClose={toogleDrawerOpen}
      >
        <Box
          width={theme.spacing(28)}
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <Box
            width={'100%'}
            height={theme.spacing(20)}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Avatar
              sx={{
                height: theme.spacing(12),
                width: theme.spacing(12),
              }}
              src="/logo_pdf.png"
            />
          </Box>
          <Divider />

          <Box flex={1}>
            <List component={'nav'}>
              {menu_list.map((menu: any) =>
                <>
                  <MenuItem
                    key={menu.to}
                    label={menu.label}
                    icon={menu.icon}
                    to={menu.to}
                    onClick={smScreen ? toogleDrawerOpen : undefined}
                  />
                </>
              )}
            </List>
          </Box>
          <Box>
            <>
              <ListItemButton onClick={toogleTheme}>
                <ListItemIcon>
                  <Icon>dark_mode</Icon>
                </ListItemIcon>
                <ListItemText primary={'Mudar Tema'} />
              </ListItemButton>
              <ListItemButton onClick={logout}>
                <ListItemIcon>
                  <Icon>exit_to_app</Icon>
                </ListItemIcon>
                <ListItemText primary={'Sair'} />
              </ListItemButton>
            </>
          </Box>
        </Box>
      </Drawer>
      <Box height={'100vh'} marginLeft={smScreen ? 0 : theme.spacing(28)}>
        {children}
      </Box>
    </>
  );
};
